import './App.css';
import Main from './pages/Main.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#424242',
      light: '#bdbdbd',
      dark: '#212121'
    },
    primary: {
      main: '#274f96'
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  );
}

export default App;