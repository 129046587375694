import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid2, Box, Button, TextField, IconButton, Typography } from '@mui/material';
import { DataGrid, GRID_COLUMN_MENU_SLOT_PROPS, GridColDef } from '@mui/x-data-grid';
import ImageRow from '../components/ImageRow.js';

import { server } from '../settings.js';
import { getDataJson } from '../common.js';
import { updateUrlState } from '../common.js';
import { getParamFromUrl } from '../common.js';

import { styled } from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import PartsTable from '../components/PartsTable.js';
import { decodeSelectedFromURL } from '../common.js';
import { getDataUrl } from '../settings.js';
import styles from '../components/img.module.css';

const Dropdown = ({ label, value, onChange, disabled, options }) => (
  <FormControl fullWidth disabled={disabled}>
    <InputLabel id={`${label}-label`}>{label}</InputLabel>
    <Select
      labelId={`${label}-label`}
      value={value}
      onChange={onChange}
      label={label}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const DropdownForm = () => {
  const [brand, setBrand] = React.useState('');
  const [type, setType] = React.useState('');
  const [size, setSize] = React.useState('');
  const [controlled, setControlled] = React.useState('');
  const [vdc, setVDC] = React.useState('');
  const [nm, setNM] = React.useState('');
  const [showStatorParams, setShowStatorParams] = useState(false);

  const [optionsBrand, setOptionsBrand] = React.useState([]);
  const [optionsType, setOptionsType] = React.useState([]);
  const [optionsSize, setOptionsSize] = React.useState([]);
  const [optionsEditable, setOptionsEditable] = React.useState([]);
  const [optionsVDC, setOptionsVDC] = React.useState([]);
  const [optionsNM, setOptionsNM] = React.useState([]);
  const [rows, setRows] = useState([]);

  const loadInitial = (fullParamsList, initialParam, setStateFunc) => {
    const list = fullParamsList.map((item) => { return item["value"] });
    const initialValue = getParamFromUrl(initialParam);
    if (list.includes(initialValue)) {
      setStateFunc(initialValue);
    } else {
      setStateFunc("");
    }
  }

  const loadBrand = (data) => {
    if (data && data.length > 0) {
      const newData = data.map((item) => { return { value: item.brand_id, label: item.brand_title } })
      console.log("loadBrand", newData);
      setOptionsBrand(newData);
      loadInitial(newData, "brand_id", setBrand);
    } else {
      setOptionsBrand([]);
      setBrand("");
    }
  }

  const loadBraketype = (data) => {
    if (data && data.length > 0) {
      const newData = data.map((item) => { return { value: item.braketype_id, label: item.braketype_title } })
      console.log("loadBraketype", newData);
      setOptionsType(newData);
      loadInitial(newData, "braketype_id", setType);
    } else {
      setOptionsType([]);
      setType("");
    }
  }

  const loadCommonSize = (data) => {
    if (data && data.length > 0) {
      const newData = data.map((item) => { return { value: item.common_size, label: item.common_size } })
      console.log("loadCommonSize", newData);
      setOptionsSize(newData);
      loadInitial(newData, "common_size", setSize);
    } else {
      setOptionsSize([]);
      setSize("");
    }
  }

  const loadEditable = (data) => {
    if (data && data.length > 0) {
      const newData = data.map((item) => { return { value: item.editable_id, label: item.editable_title } })
      console.log("loadEditable", newData);
      setOptionsEditable(newData);
      loadInitial(newData, "editable_id", setControlled);
    } else {
      setOptionsEditable([]);
      setControlled("");
    }
  }

  const loadVDC = (data) => {
    if (data && data.length > 0) {
      const newData = data.map((item) => { return { value: item.vdc, label: item.vdc } })
      console.log("loadVDC", newData);
      setOptionsVDC(newData);
      loadInitial(newData, "vdc", setVDC);
    } else {
      setOptionsVDC([]);
      setVDC("");
    }
  }

  const loadNM = (data) => {
    if (data && data.length > 0) {
      const newData = data.map((item) => { return { value: item.nm, label: item.nm } })
      console.log("loadNM", newData);
      setOptionsNM(newData);
      loadInitial(newData, "nm", setNM);
    } else {
      setOptionsNM([]);
      setNM("");
    }
  }

  const handleChangeBrand = (event) => {
    setBrand(event.target.value);
    updateUrlState("brand_id", event.target.value);
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
    updateUrlState("braketype_id", event.target.value);
  };

  const handleChangeSize = (event) => {
    setSize(event.target.value);
    updateUrlState("common_size", event.target.value);
  };

  const handleChangeControlled = (event) => {
    setControlled(event.target.value);
    updateUrlState("editable_id", event.target.value);
  };

  const handleChangeVDC = (event) => {
    setVDC(event.target.value);
    updateUrlState("vdc", event.target.value);
  };

  const handleChangeNM = (event) => {
    setNM(event.target.value);
    updateUrlState("nm", event.target.value);
  };

  useEffect(() => {
    let url = getDataUrl + "?param=brand";
    getDataJson(url, loadBrand);
  }, []);

  useEffect(() => {
    let url = getDataUrl + "?param=braketype&brand_id=" + brand;
    getDataJson(url, loadBraketype);
  }, [brand]);

  useEffect(() => {
    let url = getDataUrl + "?param=common_size&braketype_id=" + type;
    getDataJson(url, loadCommonSize);
  }, [type]);

  useEffect(() => {
    let url = getDataUrl + "?param=editable";
    getDataJson(url, loadEditable);
  }, []);

  useEffect(() => {
    let url = getDataUrl + "?param=vdc&braketype_id=" + type + "&common_size=" + size + "&editable_id=" + controlled;
    getDataJson(url, loadVDC);
  }, [type, size, controlled]);

  useEffect(() => {
    let url = getDataUrl + "?param=nm&braketype_id=" + type + "&common_size=" + size + "&editable_id=" + controlled + "&vdc=" + vdc;
    getDataJson(url, loadNM);
  }, [type, size, controlled, vdc]);

  const setLoadedRows = (loadedRows) => {
    const alreadySelected = decodeSelectedFromURL(window.location.search);
    const workingrows = [...loadedRows];
    alreadySelected.forEach(selected => {
      const row = workingrows.find(row => (row.id == selected.detailtype_id));
      if (row) {
        const option = row.options.find(option => option.detail_id === selected.selectedOption);
        if (option) {
          row.quantity = selected.quantity;
          row.selectedOption = selected.selectedOption;
        }
      }
    });
    setRows(workingrows);
  }

  useEffect(() => {
    let url = getDataUrl + "?act=getdetail&braketype_id=" + type + "&brand_id=" + brand + "&common_size=" + size + "&editable_id=" + controlled + "&vdc=" + vdc + "&nm=" + nm;
    getDataJson(url, setLoadedRows);
  }, [brand, type, controlled, size, vdc, nm]);

  const handleClearAll = () => {
    setBrand('');
    setType('');
    setSize('');
    setControlled('');
    setVDC('');
    setNM('');
    setRows([]);
    updateUrlState("brand_id", '');
    updateUrlState("braketype_id", '');
    updateUrlState("common_size", '');
    updateUrlState("editable_id", '');
    updateUrlState("vdc", '');
    updateUrlState("nm", '');
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  return (
    <Box sx={{ height: '100vh', padding: 5, paddingTop:1 }}>
    <Grid2 container spacing={5} sx={{ flexGrow: 1 }}>
      <Grid2 item xs={12} md={6} 
      flex={1} 
      minWidth={600}>
      <Grid2 container sx={{paddingBottom:3,
            alignContent:'end',
            }}
            height={100}
            gap={3}
          >
            <a href="https://drive-v.ru/">
              <img src="logo.png" alt="Атанор-Инжиниринг" style={{width:80}}/>
            </a>
            <Typography 
              fontFamily={'Monsterrat, sans-serif'}
              fontSize={24} 
              alignSelf={'end'}
            >
            Выберите параметры
          </Typography>
        </Grid2>
        <Grid2 container gap={3} direction={'column'}>
          <Grid2 container direction={'row'} gap={2} flexWrap={'nowrap'}>
            <Dropdown
              label="Бренд"
              value={brand}
              onChange={handleChangeBrand}
              options={optionsBrand}
              sx={{ width: '100%' }}
            />
            <Dropdown
              label="Модель"
              value={type}
              onChange={handleChangeType}
              disabled={!brand}
              options={optionsType}
              sx={{ width: '100%' }}
            />
            <Dropdown
              label="Типоразмер"
              value={size}
              onChange={handleChangeSize}
              disabled={!type}
              options={optionsSize}
              sx={{ width: '100%' }}
            />
          </Grid2>
          <Grid2 container gap={2} alignItems={'center'}>
            <Typography
              fontFamily={'Monsterrat, sans-serif'}
            >
              Выберите параметры статора:
            </Typography>
            {/* <Button size='small' variant='contained' onClick={() => setShowStatorParams(!showStatorParams)}>
              {showStatorParams ? 'Скрыть' : 'Показать'}
            </Button> */}
          </Grid2>
          {/* {showStatorParams && ( */}
            <Grid2 container direction={'row'} gap={2} flexWrap={'nowrap'}>
              <Dropdown
                label="Регулируемый/нерегулируемый"
                value={controlled}
                onChange={handleChangeControlled}
                disabled={!size}
                options={optionsEditable}
                sx={{ width: '100%' }}
              />
              <Dropdown
                label="Вольтаж, V DC"
                value={vdc}
                onChange={handleChangeVDC}
                disabled={!controlled}
                options={optionsVDC}
                sx={{ width: '100%' }}
              />
              <Dropdown
                label="Тормозное усиление, Nm"
                value={nm}
                onChange={handleChangeNM}
                disabled={!vdc}
                options={optionsNM}
                sx={{ width: '100%' }}
              />
            </Grid2>
          {/* )} */}
        </Grid2>
        <ImageRow rows={rows} />
      </Grid2>
      <Grid2 item xs={12} md={6} 
      flex={1} 
      minWidth={650} 
       >
        <Typography 
          fontFamily={'Monsterrat, sans-serif'}
          fontSize={24} 
          sx={{paddingBottom:3,
            alignContent:'end',
            height:75
          }}
        >
          Выберите детали
        </Typography>
        <PartsTable rows={rows} setRows={setRows} handleClearAll={handleClearAll} />
      </Grid2>
    </Grid2>
  </Box>
  );
};

export default DropdownForm;