// export async function getDataJson(url, callback, oldval = []) {
//     const response = await fetch(url); 
//         if (response.ok) { 
//           const json = await response.json();
//           if (json.toString() != oldval.toString()) {
//             if (typeof callback === 'function') {
//               console.log("getDataJson",json);
//               callback(json);
//             }
//           } else 
//           {
//             callback([]);
//           };
//         } else {
//             callback([]);
//             alert(`Ошибка HTTP: ${response.status}`);
//         }
//   }

  export async function getDataJson(url, callback, oldval = []) {
    const response = await fetch(url); 
        if (response.ok) { 
          const json = await response.json();
          if (json.toString()) {
            if (typeof callback === 'function') {
              console.log("getDataJson",json);
              callback(json);
            }
          } else 
          {
            callback([]);
          };
        } else {
            callback([]);
            //alert(`Ошибка HTTP: ${response.status}`);
        }
  }
  

  export function updateUrlState(param, value) {
    if (window.history.pushState) {
        // Получаем текущий URL
        var baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        var searchParams = new URLSearchParams(window.location.search);

        // Обновляем или добавляем параметр
        searchParams.set(param, value);

        // Собираем новый URL
        var newUrl = baseUrl + '?' + searchParams.toString();

        // Обновляем историю браузера
        window.history.pushState(null, null, newUrl);
    } else {
        console.warn('History API не поддерживается');
    }
}

export function removeUrlParam(param) {
    if (window.history.pushState) {
        // Получаем текущий URL
        var baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        var searchParams = new URLSearchParams(window.location.search);

        // Удаляем параметр
        searchParams.delete(param);

        // Собираем новый URL
        var newUrl = baseUrl + (searchParams.toString() ? '?' + searchParams.toString() : '');

        // Обновляем историю браузера
        window.history.pushState(null, null, newUrl);
    } else {
        console.warn('History API не поддерживается');
    }
}

export function getParamFromUrl(param) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param) || "";
}

export function encodeSelectedToURL(selected) {
  const urlObj = new URL(window.location.href);
  const params = new URLSearchParams(urlObj.search);

  // Удаляем все старые значения, связанные с переменной selected
  for (const key of params.keys()) {
    if (key.startsWith('selected[')) {
        params.delete(key);
    }
}

  selected.forEach((item, index) => {
      for (const key in item) {
          if (item[key] !== undefined) {
              params.append(`selected[${index}][${key}]`, item[key]);
          }
      }
  });

  urlObj.search = params.toString();
  const newUrl = urlObj.toString();
  window.history.pushState(null, null, newUrl);
}


export function decodeSelectedFromURL() {
  console.log(window.location.href);
  const urlObj = new URL(window.location.href);
  //const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const selected = [];

  params.forEach((value, key) => {
      const match = key.match(/^selected\[(\d+)\]\[(\w+)\]$/);
      if (match) {
          const index = parseInt(match[1]);
          const prop = match[2];

          if (!selected[index]) {
              selected[index] = {};
          }

          selected[index][prop] = value;
      }
  });

  return selected.filter(item => item !== undefined);
}

const checkResponse = (res) => {
  return res.ok ? res.json() : res.json().then((err) => Promise.reject(err));
}

export const postJson = (url, arr) => {
  return fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify(arr),
  })
  .then(checkResponse)
}

