let url = "";

if (window.location.host.includes("localhost")) {
    url = 'http://localhost:8080';
}

export const server = {
    api:  url
};

export const getDataUrl = server.api + "/api/breakes.php";
export const sendSelectedUrl = server.api + "/api/breakes_send.php";