import React from 'react';
import styles from './img.module.css';
import { useState } from 'react';

const ImageRow = ({ rows }) => {

  const workingRes = rows.map((item) => {
    // Находим элемент в массиве options, у которого detail_id совпадает с selectedOption
    const matchingOption = item.options.find((option) => option.detail_id === item.selectedOption);
  
    // Если такой элемент найден, берем subdetailtype_id, иначе устанавливаем null или другое значение по умолчанию
    const subdetailtype_id = matchingOption ? matchingOption.subdetailtype_id : null;
  
    // Возвращаем объект с добавленным полем subdetailtype_id
    return {
      selectedOption: item.selectedOption,
      quantity: item.quantity,
      options: item.options,
      subdetailtype_id: subdetailtype_id
    };
  });
  
  console.log("workingRes", workingRes)
  // console.log("ROWS", rows)

  const frames = [
    { id: 8, text: 'Ручное растормаживание', line_id: 8 },
    { id: 5, text: 'Статор', line_id: 5 },
    { id: 1, text: 'Аллюминиевый ротор', line_id: 1 },
    { id: 2, text: 'Втулка', line_id: 2 },
    { id: 7, text: 'Защитное кольцо', line_id: 7},
    { id: 3, text: 'Фрикционная пластина', line_id: 3 },
    { id: 4, text: 'Фланец', line_id: 4 },
    { id: 16, text: 'Заглушка вала', line_id: 16 },
    { id: 15, text: 'Уплотнительное кольцо вала', line_id: 15 }

  ];

return (
  <div className={styles.mainContainer}>
    <img className={styles.img} src='/detailtype_images/background-img.jpg' alt="Background" />
    {frames.map((frame) => {

      const highlightFrameItem = workingRes.find((resStr) => resStr.subdetailtype_id == frame.id && resStr.quantity > 0);
      const highlightFrame = highlightFrameItem ? true : false;

      return (
        <>
        <div
          key={frame.id}
          //className={`${styles[`frame${frame.id}`]} ${styles.frame} ${highlightFrame ? styles.highlight : ''}`}
          className={`${styles[`frame${frame.id}`]} ${styles.frame} ${highlightFrame ? styles.highlight : ''}`}
        >
          {frame.text}
        </div>
        <div key={frame.line_id + "_line"} className={`${styles[`line${frame.line_id}`]} ${styles.line}`}></div>
        </>
      );
    })}
  </div>
);
};


export default ImageRow;