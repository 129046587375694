import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useEffect, useState } from 'react';
import { sendSelectedUrl } from '../settings';
import { postJson } from '../common';
import { Grid2 } from '@mui/material';

const MessageDialog = ({ open, onClose, message }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Сообщение</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  );
};

export default function FormDialog(props) {
  const sendingData = props.sendingData;
  const [open, setOpen] = React.useState(false);
  const firstFieldRef = useRef(null);
  const [messageOpen, setMessageOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const handleClearAll = props.handleClearAll;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMessageClose = () => {
    setMessageOpen(false);
  };

  useEffect(() => {
    if (open && firstFieldRef.current) {
      firstFieldRef.current.focus();
    }
  }, [open]);

  const fields = [
    { id: 'secondname', name: 'secondName', label: 'Фамилия', type: 'text' },
    { id: 'firstname', name: 'firstName', label: 'Имя', type: 'text' },
    { id: 'phonenumber', name: 'phoneNumber', label: 'Телефон', type: 'tel' },
    { id: 'email', name: 'email', label: 'Эл. почта', type: 'email' },
    { id: 'inn', name: 'inn', label: 'ИНН', type: 'text' },
    { id: 'comment', name: 'comment', label: 'Оставить комментарий', type: 'text' },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());

    const allSendingData = { ...formJson, data: sendingData };
    console.log('Отправляемые данные:', allSendingData);

    postJson(sendSelectedUrl, { form: allSendingData })
      .then((res) => {
        // открылось что-то жизнеутверждающее
        if (res["status"] == "success") {
          const message = "Ваш запрос успешно отправлен! В ближайшее время с вами свяжется менеджер";
          setMessage(message);
          setMessageOpen(true);
          handleClose();
        } else {
          setMessage("Ошибка! Напишите нам на почту info@drive-v.ru");
          setMessageOpen(true);
          handleClose();
        }
      })
      .catch((err) => {
        // вышло сообщение об ошибке и 'напишите на info@drive-v...'
        setMessage("Ошибка! Напишите нам на почту info@drive-v.ru");
        setMessageOpen(true);
        handleClose();
      });
  };

  return (
    <React.Fragment>
      <Grid2 container sx={{height: 50, width: '100%', marginBottom: 20, justifyContent:'end' }}>
        <Button onClick={handleClearAll} style={{ marginRight: 10 }}>
          Сбросить выбор
        </Button>
        <Button variant="contained" onClick={handleClickOpen} disabled={sendingData==0}>
          Отправить
        </Button>
      </Grid2>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>Отправить форму</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Введите ваши данные
          </DialogContentText>
          {fields.map((field, index) => (
            <TextField
              key={field.id}
              autoFocus={index === 0}
              inputRef={index === 0 ? firstFieldRef : null}
              required={field.id !== 'comment'}
              margin="dense"
              id={field.id}
              name={field.name}
              label={field.label}
              type={field.type}
              fullWidth
              variant="standard"
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button variant='contained' type="submit">Отправить форму</Button>
        </DialogActions>
      </Dialog>
      <MessageDialog open={messageOpen} onClose={handleMessageClose} message={message} />
    </React.Fragment>
  );
}