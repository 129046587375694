import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid2, Box, Button, TextField, IconButton } from '@mui/material';
import { DataGrid, GRID_COLUMN_MENU_SLOT_PROPS, GridColDef, GridToolbar, gridColumnLookupSelector } from '@mui/x-data-grid';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { server} from '../settings';
import { getDataJson } from '../common';
import FormDialog from './DialogModal';
import { updateUrlState } from '../common';
import { encodeSelectedToURL } from '../common';
import './PartsTable.css'; 

const uploadSelectedTableData = (rows) => {
    const selected = rows.map((row) => {return {detailtype_id: row.id, selectedOption: row.selectedOption, quantity: row.quantity}});
    encodeSelectedToURL(selected);
}

const DropdownCell = ({ value, options, onChange }) => {
    return (
      <FormControl fullWidth sx={{ paddingTop: 2 }}>
        <InputLabel id="dropdown-label" sx={{ paddingTop: 2 }}>Выберите вариант</InputLabel>
        <Select
          labelId="dropdown-label"
          value={value}
          label="Выберите вариант"
          onChange={(e) => onChange(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', 
            },
            '& .MuiSelect-icon': {
              color: 'inherit', 
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.detail_id} value={option.detail_id} sx={{ fontSize: 14 }}>
              {option.consumer_title}
            </MenuItem>
          ))
          }
        </Select>
      </FormControl>
    );
  };
  
  const QuantityInput = ({ value, onChange, isDisabled }) => {
    const handleIncrement = () => {
      if (!isDisabled) {
        onChange(+value + 1);
      }
    };
  
    const handleDecrement = () => {
      if (!isDisabled && value > 0) {
        onChange(+value - 1);
      }
    };
  
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <IconButton onClick={handleDecrement} sx={{ width: 40, height: 40 }} disabled={isDisabled}>
          <RemoveIcon />
        </IconButton>
        <TextField
          type="number"
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value, 10))}
          sx={{
            width: 40,
            '& .MuiInputBase-input': {
              textAlign: 'center',
              height: 40,
              padding: 0,
            },
            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            // marginTop: 1
          }}
          disabled={isDisabled}
        />
        <IconButton onClick={handleIncrement} sx={{ width: 40, height: 40 }} disabled={isDisabled}>
          <AddIcon />
        </IconButton>
      </div>
    );
  };
  
  
function getPrice(items, detail_id) {
    const item = items.find(item => detail_id === item.detail_id);
    return item ? item.price : null;
}

export default function PartsTable(props) {
  const rows = props.rows;
  const setRows = props.setRows;
  const handleClearAll = props.handleClearAll;

  const columns = [
    { field: 'name', headerName: 'Наименование запчасти', width: 230 },
    {
      field: 'options',
      headerName: 'Выбор',
      flex: 1, // Используем flex для растягивания столбца
      renderCell: (params) => (
        <DropdownCell
          value={params.row.selectedOption || ''}
          options={params.value ?? []}
          onChange={(newValue) => {
            const newRows = rows.map((row) =>
              row.id === params.id ? 
              { ...row, selectedOption: newValue, price: getPrice(params.value, newValue), quantity: newValue > 0 ? (row.quantity > 0 ? row.quantity : 1) : 0 } 
              : row
            );
            setRows(newRows);
            uploadSelectedTableData(newRows);
          }}
        />
      ),
    },
    {
      field: 'quantity',
      headerName: 'Количество',
      width: 150,
      renderCell: (params) => (
        <QuantityInput
          value={params.row.quantity || 0}
          onChange={(newValue) => {
            const newRows = rows.map((row) =>
              row.id === params.id ? 
            { ...row, quantity: newValue, selectedOption: newValue === 0 ? 0 : row.selectedOption } 
            : row
            );
            setRows(newRows);
            uploadSelectedTableData(newRows);
          }}
          isDisabled={!params.row.selectedOption || params.row.selectedOption == 0}
        />
      ),
    },
  ];

  function getSendingData(data) {
    return data.reduce((result, item) => {
      if (item.quantity > 0) {
        result.push({
          selectedOption: item.selectedOption,
          quantity: item.quantity
        });
      }
      return result;
    }, []);
  }

  const getRowClassName = (params) => {
    return params.row.selectedOption > 0 && params.row.quantity > 0 ? 'highlighted-row' : '';
  };

  return (
    <Grid2 container direction={'column'} gap={2}>
      <DataGrid 
        rowHeight={80} 
        rows={rows} 
        columns={columns.map(column => ({
          ...column,
          sortable: false, 
        }))}
        disableColumnMenu
        disableColumnFilter
        hideFooterPagination={true}
        getRowClassName={getRowClassName}
        disableRowSelectionOnClick={true}
        fullWidth
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize:16,
            fontWeight: 600,
          },
          width: '100%'
        }}
      />
      <FormDialog sendingData={getSendingData(rows)} handleClearAll={handleClearAll}/>
    </Grid2>
  );
}